import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Rating from 'react-rating'
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as emptyHeart} from '@fortawesome/free-regular-svg-icons';
import { useDeleteFavouriteMutation, useUpdateFavouriteMutation } from '../app/api/favouriteSlice';

function FavouriteItem({ element, favourite, favourites, idx }) {
  const [deleteFavourite, ] = useDeleteFavouriteMutation();
  const [updateFavourite, ] = useUpdateFavouriteMutation();


  const ratingCount = async (favouriteId, rating) => {
    try {
        await updateFavourite({ favouriteId, rating })
    } catch (error) {
        console.log(error)
    }
  }


  const handleClick = async (favouriteId) => {
      try {
          await deleteFavourite(favouriteId)          
      } catch (error) {
          console.log(error)
      }
  }

  const colorDict = {
      'm': 'male-color',
      'f': 'female-color'
  }
  

  return (
    <div>
        <div className='favourites-list'>
            <div className={"favourite-name-text min-w-0 " + (favourite.customGender? colorDict[favourite.customGender] : colorDict[favourite.name.gender])}><span className='truncate'>{favourite.customGender ? favourite.customName : favourite.name.name}</span></div>
            <div className="flex justify-center items-center leading-4">

                <Rating
                    emptySymbol = {<FontAwesomeIcon icon={emptyHeart} className="rating-icon rating-icon-empty"/>}
                    fullSymbol = {<FontAwesomeIcon icon={faHeart} className="rating-icon rating-icon-full"/>}
                    onClick={(rating) => ratingCount(favourite.favouriteId, rating)}
                    initialRating={favourite.rating}
                />
                
            </div>
            <div className="delete-btn">
                <button className="delete-favourite-btn" onClick={() => handleClick(favourite.favouriteId)}>&times;</button>
            </div>
        </div>
    </div>
  )
}

export default FavouriteItem