import { useSelector, useDispatch } from 'react-redux'
import Cloud from '../components/Cloud'
import { setMenu } from '../app/features/menuSlice'
import { setFilter } from '../app/features/filterSlice'
import { useFetchFavouritesQuery } from '../app/api/favouriteSlice'
import { useLazyFetchNamesQuery } from '../app/api/nameSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from 'react'

function Search () {

    const filter = useSelector((state) => state.filter)
    const { data: favourites, isLoading:favouriteIsLoading } = useFetchFavouritesQuery()
    const favouriteNames = favourites? Object.fromEntries(favourites.map(el => ([el.customName? el.customName : el.name.name, el.favouriteId]))) : {}
    const [fetchNames, {data: names, isLoading: nameIsLoading}] = useLazyFetchNamesQuery(filter)
    const trigger = useSelector((state) => state.filter.triggerUpdate)
    const [initialLoad, setInitialLoad] = useState(true);

    useEffect(() => {
        if (initialLoad) {
            fetchNames(filter)
            setInitialLoad(false);
        }
        
    }, [initialLoad, trigger]);

    useEffect(() => {
        if (!initialLoad) {
          fetchNames(filter);
        }
    }, [trigger, fetchNames]);


    return (
        <>
            <div className="main-container sky">
                {nameIsLoading || favouriteIsLoading ? 
                    <div id="heart" >
                        <img className="bottom" src="/images/heart.png" alt="heart loading"></img>
                    </div> : 
                    <>
                        <FilterButton></FilterButton>

                        <div className="inner-sky">
                        {names?.names?.map((batch, i) => (
                            <div className="sky-wrapper" key={i}>
                                <div className="sky-container">
                                    {batch.map((name, j) => (
                                        <Cloud 
                                            key={j}
                                            name={name.name}
                                            nameGender={name.gender}
                                            nameId={name.id}
                                            favouriteId={favouriteNames[name.name]}
                                            left={names.coordinates[i][j]['topLeft']['x']}
                                            top={names.coordinates[i][j]['topLeft']['y']}
                                            initiallyActivated={favouriteNames[name.name]?true:false}
                                        ></Cloud>
                                    ))}
                                </div>
                            </div>
                        ))}
                        </div>
                </>
                }
            </div>
        </>
    )
}


const FilterButton = () => {
    const dispatch = useDispatch()
    const filter = useSelector((state) => state.filter)
    const [filterCounter, setFilterCounter] = useState(0)
    const menu = useSelector((state) => state.menu)

    const clickHandle = () => {
        dispatch(setMenu({...menu, menuY: '10%', zIndex: 30, category: 'filterMenu', top: 0, left: 0 }));

        // remember previous state for restoring the old filter in case the user cancel the action
        dispatch(setFilter({...filter, prev:{"gender": filter.gender, "name":filter.name, "triggerUpdate":filter.triggerUpdate}}))
    }

    useEffect(() => {
        var counter = 0
        if (filter.gender!=='') {counter+=1}
        if (filter.name.length.length>0) {counter+=1}
        if (filter.name.startswith!=="") {counter+=1}
        if (filter.name.endswith!=="") {counter+=1}
        setFilterCounter(counter)
    }, [filter]);

    return (
        <div className='sticky top-[30px] ml-auto mr-12 cursor-pointer z-10 w-28 h-28 flex justify-center items-center'>
            <button onClick={clickHandle} className='text-neutralDarker bg-neutral w-full h-full rounded-full shadow-md grid justify-items-center items-center'>
                <FontAwesomeIcon icon={faFilter} className="text-[30px] pt-3 col-start-1 col-end-2 row-start-1 row-end-2"/>
                <div className={"col-start-1 col-end-2 row-start-1 row-end-2 w-1/2 justify-self-end pb-6 " + (filterCounter===0 && " hidden")}>
                    <div className='rounded-[15px] bg-highlight min-w-[25px] w-fit text-center'>
                        <div className="text-neutral p-[0.2em_0.4em_0.1em_0.5em]">{filterCounter}</div>
                    </div>
                </div>
            </button>
        </div>
    )
}

export default Search 