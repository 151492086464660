import { createSlice } from '@reduxjs/toolkit'

const  initialState = {
    menuY: '100%',
    zIndex: -1,
    category: "menu"
}

const menuSlice = createSlice({
    name: 'menu',
    initialState: initialState,
    reducers: {
        reset: () => initialState,
        setMenuY(state, action) {
          state.menuY = action.payload;
        },
        setMenuZIndex(state, action) {
            state.zIndex = action.payload;
        },
        setMenuCategory(state, action) {
            state.category = action.payload;
        }, 
        setMenu(state, action) {
            state.category = action.payload.category
            state.menuY = action.payload.menuY
            state.zIndex = action.payload.zIndex
        }
    }
});

export const { 
    reset,
    setMenuY, 
    setMenuZIndex, 
    setMenuCategory,
    setMenu
} = menuSlice.actions;
export default menuSlice.reducer