import React from "react";
import { useRef, useState } from 'react'
import { useFetchFavouritesQuery, useCreateFavouriteMutation } from "../app/api/favouriteSlice";
import FavouriteItem from '../components/FavouriteItem'
import FavouritePrint from '../components/FavouritePrint';
import ReactToPrint from "react-to-print";
import { useAutoAnimate } from '@formkit/auto-animate/react'
import * as RadioGroup from '@radix-ui/react-radio-group';
import Axios from "axios";



function MyFavourites() {
    const [parent, enableAnimations] = useAutoAnimate(/* optional config */)
    const [createFavourite, ] = useCreateFavouriteMutation();
    const { data: favourites, isLoading } = useFetchFavouritesQuery()

    const [popUpActive, setPopUpActive] = useState(false) 
    const [name, setName] = useState('')
    const [isLoadingPDF, setIsLoadingPDF] = useState(false)
    const [customNameError, setCustomNameError] = useState({'isError':false, 'message':""})
    const [gender, setGender] = useState('f')
    let componentRef = useRef(null); 
    


    const downloadPdf = () => {
        setIsLoadingPDF(true)
        Axios.post('/api/favourite/pdf', {
                'favourites': favourites
            }, 
            {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf',
                }
            },
        ).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'My Favourite Babynames www.mybabynamefinder.com.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();
            setIsLoadingPDF(false)
        })
        .catch((error) => console.log(error));
    }


    const addCustomName = async () => {
        if (name.length > 0 && name.length <= 25) {
            await createFavourite({'customName': name, 'customGender': gender}).unwrap().then(() => {
                setPopUpActive(false) 
                setGender('f')
                setName('')
            }).catch((rejected) => console.error(rejected));
        } else {
            if(name.length===0) {
                setCustomNameError({"isError":true, "message":"ein Name fehlt"})
            }
            if (name.length > 25) {
                setCustomNameError({"isError":true, "message":"dieser Name ist zu lang"})
            }
        }
    }

    const cancelAddName = () => {
        setPopUpActive(false)
        setName('')
        setGender('f')
    }


    const modalClick = (event) => {
        // This functions prevents clicking somewhere in the modal
        // and closing the modal as the background was clicked too
        event.stopPropagation();
    }


    return (
        <>
            {!isLoading && 
                <section className='px-0 py-0 pb-2em pr-[5%] max-w-34em w-full h-fit-content sticky bottom-[7em] z-10 flex justify-end min-[550px]:px-0 min-[550px]:mx-auto min-[550px]:max-w-[34em]'>
                    <button className='w-20 h-20 rounded-full p-3 shadow-sm hover:bg-primaryDark' onClick={()=>setPopUpActive(true)}>
                        <img alt="add favourite name button" src="./images/cross.png"></img>
                    </button>
                </section>
            } 


            <div className="main-container favourite-container">
                {isLoading? 
                    <div id="heart" >
                        <img className="bottom" src="/images/heart.png" alt="heart loading"></img>
                    </div> : <>
                    
                    <section className='favourite-btn-container text'>
                        <a className="text-sm bg-primary text-neutral rounded-full cursor-pointer flex justify-center items-center font-bold hover:bg-primaryDark" onClick={downloadPdf} >{isLoadingPDF? "..." : "Download"}</a>
                        <div></div>
                        <FavouritePrint names={favourites} ref={(el) => (componentRef = el)} />
                        <ReactToPrint
                            trigger={() => <button className='py-4 rounded-full text-sm font-bold hover:bg-primaryDark'>Drucken</button>}
                            content={() => componentRef}
                        />
                    </section>

                    <section>
                        <ul className="pb-[8em]" ref={parent}>
                            {favourites?.length > 0 ? (
                                <>
                                    {favourites?.map((favourite, idx) => {
                                        return <FavouriteItem  key={favourite.favouriteId} favourite={favourite} favourites={favourites} idx={idx}/>
                                    })}
                                </>
                            ) : (<h3>Keine Namen favourisiert</h3>)}
                        </ul>
                    </section>
                </>}
            </div>

            <div className={"absolute top-0 left-0 w-full h-full py-[7em] z-20 min-[650px]:pl-[12em] min-[650px]:py-0"+ (popUpActive ? " flex" : " hidden")}>
                <div onClick={cancelAddName} className='pop-up-background'>
                <div onClick={(event) => modalClick(event)} className='pop-up-container'>
                    <div className="pb-[8px]">
                        <label className="pop-up-text">Trage einen Namen ein</label>
                        <input value={name} onChange={(e)=>{setName(e.target.value); setCustomNameError({"isError":false, "message":""})}} placeholder='Name...'></input>
                        <div className="flex justify-end text-highlight">{customNameError["isError"] ? customNameError["message"] : '\0'}</div>
                    </div>
                    
                    <label className="pop-up-text">Geschlecht</label>

                    <RadioGroup.Root
                    className="flex flex-col gap-2.5 mb-[28px] required:text-primary"
                    value={gender}
                    aria-label="View density"
                    onValueChange={()=>{setGender(gender==="f" ? "m" : "f")}}
                    required
                    >
                        <div className="flex items-center">
                            <RadioGroup.Item
                            className="bg-neutral w-[20px] h-[20px] rounded-full focus:shadow-[0_0_0_2px] focus:shadow-[--primary] hover:shadow-[0_0_0_2px] hover:shadow-[--primary] shadow-[0_0_0_1px] shadow-[--primary] cursor-pointer"
                            value="f"
                            id="r1"
                            >
                            <RadioGroup.Indicator className="flex items-center justify-center w-full h-full relative after:bg-[--primary] after:content-[''] after:block after:w-[12px] after:h-[12px] after:rounded-[50%]" />
                            </RadioGroup.Item>
                            <label className="leading-none pl-[15px]" htmlFor="r1">
                            weiblich
                            </label>
                        </div>
                        <div className="flex items-center">
                            <RadioGroup.Item
                            className="bg-neutral w-[20px] h-[20px] rounded-full focus:shadow-[0_0_0_2px] focus:shadow-[--primary] hover:shadow-[0_0_0_2px] hover:shadow-[--primary] shadow-[0_0_0_1px] shadow-[--primary] cursor-pointer"
                            value="m"
                            id="r2"
                            >
                            <RadioGroup.Indicator className="flex items-center justify-center w-full h-full relative after:bg-[--primary] after:content-[''] after:block after:w-[12px] after:h-[12px] after:rounded-[50%]" />
                            </RadioGroup.Item>
                            <label className="leading-none pl-[15px]" htmlFor="r2">
                            männlich
                            </label>
                        </div>
                    </RadioGroup.Root>
                    
                    <div className='pop-up-btn-container'>
                        <button onClick={cancelAddName} className='text-sm font-bold px-8 py-2 rounded-full hover:bg-primaryDark'>Abbrechen</button>
                        <button onClick={addCustomName} className='text-sm font-bold px-8 py-2 rounded-full hover:bg-primaryDark' >Hinzufügen</button>
                    </div>
                </div>
                </div>
            </div>
        </>   
    )
}

export default MyFavourites