import { useDispatch } from 'react-redux'
import { useDeleteFavouriteMutation, useCreateFavouriteMutation } from "../app/api/favouriteSlice";
import { useCreateUserMutation } from '../app/api/userSlice';
import { saveAccessToken } from '../utils/localStorage';
import { useState, memo } from 'react'




function Cloud({top, left, favouriteId, name, nameGender, nameId, initiallyActivated}) {

    const [active, setActive] = useState(initiallyActivated)
    const [createFavourite, ] = useCreateFavouriteMutation();
    const [deleteFavourite, ] = useDeleteFavouriteMutation();
    const [createUser, ] = useCreateUserMutation();


    const clickCloud = async () => {
        // the heart is active and the user has clicked.
        // the user does not like the name anymore. remove it from favoruties
        if (active) {
            setActive(false)
            deleteFavourite(favouriteId).unwrap()
                .catch((error)=>{
                    console.log(error)
                    setActive(true)
                })
        } else {
            setActive(true)
            await createFavourite({'nameId':nameId, 'name':name}).unwrap()
                .catch((error) => {
                    if (error.status===401) {
                        createUser().unwrap().then((user) => {
                            saveAccessToken(user)
                            createFavourite({'nameId':nameId, 'name':name})
                                .catch((error)=>{
                                    console.log(error)
                                    setActive(false)
                                })
                        })
                        .catch((error) => {
                            console.log(error)
                            setActive(false)
                        })
                    } else {  
                        console.log(error)
                        setActive(false)
                    }
                })
        }
    }


    return (
        <div className="sky-icon-container" style={{top:top, left:left}} onClick={clickCloud}>

            {active &&
                <img className={"sky-icon-heart heartFavourite visible" } src="/images/heart.png" alt="heart name"></img>
            }
            {!active && 
                <img className={"sky-icon-cloud cloudFavourite visible"} src="/images/cloud.png" alt="cloud name"></img>
            }

            <p className={"sky-icon-text cloudTitleFavourite " + (nameGender === 'm' ? "male-color" : "female-color") + (active ? " sky-icon-btn-active heartTitleFavourite" : "")}>
                {name}
            </p>
            <button name-id={nameId} name={name} 
                    className={"sky-icon-btn"} favourite-id={favouriteId}
            ></button>
        </div>
    )
}


export default memo(Cloud)