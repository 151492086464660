import { apiSlice } from "./apiSlice";


const FAVOURITE_URL = "/api/favourite"

export const favouriteApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createFavourite: builder.mutation({
      query: (newFavourite) => ({
        url: `${FAVOURITE_URL}`,
        method: "POST",
        body: newFavourite,
        prepareHeaders: (headers) => {
            return headers
        },
      }),
      invalidatesTags: ['Favourite']
    }),

    updateFavourite: builder.mutation({
      query: ({ favouriteId, rating }) => ({
        url: `${FAVOURITE_URL}/${favouriteId}`,
        method: "PUT",
        body: {'rating': rating},
      }),
      async onQueryStarted({favouriteId, rating}, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData('fetchFavourites', undefined, draft => {
            const favouriteIndex = draft.findIndex(favourite => favourite.favouriteId === favouriteId)
            console.log('++++++++++')
            console.log(favouriteIndex)
            
            if (favouriteIndex > -1) {
              draft[favouriteIndex].rating = rating
            }
            return draft.sort((a, b) => (b.rating - a.rating))
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
    }),

    deleteFavourite: builder.mutation({
      query: ( favouriteId ) => ({
        url: `${FAVOURITE_URL}/${favouriteId}`,
        method: 'DELETE',
      }),
      async onQueryStarted(favouriteId, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData('fetchFavourites', undefined, draft => {
            return draft.filter(favourite => favourite.favouriteId != favouriteId)
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
    }),

    fetchFavourites: builder.query({
      query: () => `${FAVOURITE_URL}`,
      providesTags: ['Favourite']
    }),
  }),
});

export const {
  useCreateFavouriteMutation,
  useUpdateFavouriteMutation,
  useDeleteFavouriteMutation,
  useFetchFavouritesQuery,
  
} = favouriteApiSlice;