import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { setCurrentPage } from '../app/features/currentPageSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faHeart, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useFetchFavouritesQuery } from '../app/api/favouriteSlice'


function Bar() {

    const dispatch = useDispatch();
    const { data: favourites } = useFetchFavouritesQuery()

    const navigate = useNavigate();
    const location = useLocation().pathname;
    
    
    function handleClick(path) {
        navigate(path);
        dispatch(setCurrentPage(path))
    }

    return (
        <div className="bar main-grid">

            <div className='icon-wrapper'>
                <button className={'grid grid-rows-[40px_auto] text-neutralDarker cursor-pointer text-center transition-colors duration-500 ease-in-out bg-[transparent] ' + (location === '/' && 'dark')} onClick={() => handleClick('/')}>
                    <FontAwesomeIcon icon={faHouse} className="bar-icon bar-icon-search"/>
                    <p className='bar-title'>Suche</p>
                </button>
            </div>

            <div className='icon-wrapper'>
                <button className={"grid grid-rows-[40px_auto] text-neutralDarker cursor-pointer text-center transition-colors duration-500 ease-in-out bg-[transparent] "+ (location === '/myfavourites' && 'dark')} onClick={() => handleClick('/myfavourites')}>
                    <FontAwesomeIcon icon={faHeart} className='bar-icon bar-icon-favs bar-icon-heart' />
                    <div className="col-start-1 col-end-2 row-start-1 row-end-2 w-1/2 justify-self-end">
                        <div className={'rounded-[15px] bg-highlight min-w-[25px] w-fit text-center ' + ((!favourites || favourites?.length === 0) && 'hidden')}>
                            <div className="text-neutral p-[0.2em_0.4em_0.1em_0.5em]">{favourites?.length}</div>
                        </div>
                    </div>
                    <p className='bar-title'>Favouriten</p>
                </button>
            </div>

            <div className='icon-wrapper'>
                <button className={'grid grid-rows-[40px_auto] text-neutralDarker cursor-pointer text-center transition-colors duration-500 ease-in-out bg-[transparent] ' + (location === '/info' && 'dark')} onClick={() => handleClick('/info')}>
                    <FontAwesomeIcon icon={faInfoCircle} className="bar-icon bar-icon-info"/>
                    <p className='bar-title'>Info</p>
                </button>
            </div>

            <div className={'bar-icon-imprint ' + (location === "/imprint"? "dark" : "")} onClick={() => handleClick('/imprint')}>
                <p className='bar-title'>Impressum</p>
            </div>
        </div>
    )
}


export default Bar