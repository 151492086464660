const getAccessToken = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    return user? user?.token : undefined
}

const saveAccessToken = (user) => {
    localStorage.setItem("user", JSON.stringify(user))
}

export {
    getAccessToken, 
    saveAccessToken
}