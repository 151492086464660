import { apiSlice } from "./apiSlice";


const NAME_URL = "/api/names"

export const nameApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // fetchNames: builder.mutation({
    //     query: () => ({
    //       url: `${NAME_URL}`,
    //       method: "POST",
    //       body: {},
    //     }),
    //     transformResponse: (response) => console.log(response),
    //     providesTags: ['Name']
    //   })
    fetchNames: builder.query({
      query: (filter) => ({
        url:`${NAME_URL}`,
        method: "POST",
        body: filter,
        providesTags: ['Name']
      })
    }),
  }),
});

export const {
    // useFetchNamesMutation,
    useFetchNamesQuery,
    useLazyFetchNamesQuery
} = nameApiSlice;