// /bbname/Scripts/activate.bat
// https://www.radix-ui.com/primitives/docs/components/context-menu - für komplizierte Sachen, wie das Karussel
import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronRight, faChevronLeft, faStar} from '@fortawesome/free-solid-svg-icons'
// import TestimonialCarousel from '../components/TestimonialCarousel';

function Info() {

    return (
      <div className='row-start-2 col-start-1 row-end-2 col-end-1 pb-[4em] overflow-y-auto min-[650px]:row-start-1 min-[650px]:col-start-2 min-[650px]:row-end-3 min-[650px]:col-end-2 bg-neutral'>
        <header>
          <div className='flex flex-col min-[650px]:grid min-[650px]:place-items-center'>
            <div className="flex items-center justify-center min-[650px]:row-start-1  min-[650px]:col-start-1">
            <img
                alt="Jetzt Namen finden"
                src="/images/storch2.png"
                className="block mt-2 max-w-[75%] ml-auto"
                />

              {/* <img
                alt="Jetzt Namen finden"
                src="/images/hero_Wolke.png"
                className="block mt-2 max-w-[75%] ml-auto"
                /> */}
            </div>

            <div className="flex flex-col justify-center self-end min-[650px]:justify-self-start min-[650px]:row-start-1  min-[650px]:col-start-1">
              <h1 className='className="text-3xl font-bold mb-4 text-center min-[650px]:text-left px-[5%] min-[650px]:pb-[5%]' style={{ fontSize: '20px', color: '#0069af' }}>Willkommen bei <a href="/"className={
          "text-primary hover:text-secondary focus:text-secondary"}>myBabyNameFinder</a>!</h1>
              <h2 className="text-center min-[650px]:text-left px-[5%] text font-semibold block mt-2 text-gray-600 min-[650px]:pb-[5%]" style={{ fontSize: '16px', color: '#666666' }}>myBabyNameFinder ist deine kostenlose Seite um einen passenden Namen für dein Baby zu finden.</h2>
              <div className='flex justify-center space-x-4 min-[650px]:justify-start min-[650px]:px-[5%]'>
                {/* <a href="/" className="no-deco bg-primary text-neutral w-[12em] py-[1em] px-[1em] rounded-full cursor-pointer flex justify-center items-center font-bold hover:bg-primaryDark text-center">
                    Suche Starten
                </a> */}
                <a href="/" className="no-deco bg-secondary text-neutral w-[12em] py-[1em] px-[1em] rounded-full cursor-pointer flex justify-center items-center font-bold hover:bg-primaryDark text-center">
                    Mädchennamen
                </a>
                <a href="/" className="no-deco bg-primary text-neutral w-[12em] py-[1em] px-[1em] rounded-full cursor-pointer flex justify-center items-center font-bold hover:bg-primaryDark text-center">
                    Jungennamen
                </a>
              </div>
            </div>
          </div>
        </header>

      <section className="mt-8">
        <div className='flex flex-col md:flex-row min-[770px]:space-x-4 space-y-4 min-[770px]:space-y-0 items-start'>
          <img src="/images/teddy.jpg" alt="Baby Namen einfach filtern" className="my-4 min-[770px]:w-1/2" />
          
          <div className='min-[770px]:w-1/2 min-[770px]:pt-[5%]'>
          <h2 className="text-3xl font-bold mb-4 px-[5%]" style={{ fontSize: '20px', color: '#0069af' }}>
            Babynamen finden
            <small className="text font-semibold block mt-2 text-gray-600" style={{ fontSize: '16px', color: '#666666' }}>
              war noch nie so einfach!
            </small>
          </h2>
          <p className="px-[5%]">
          Die Suche nach dem perfekten Namen für dein Baby kann eine aufregende, 
          aber auch herausfordernde Aufgabe sein. Bei BabyNameFinder unterstützen 
          wir dich dabei, den idealen Namen für deinen kleinen Schatz zu finden.
          <br></br>
          <br></br> 
          Egal ob klassisch, modern oder ausgefallen – mit unserer Sammlung von 
          Namen kannst du dich inspirieren lassen, deine liebsten Namen favorisieren und bewerten, 
          um die beste Auswahl zu treffen.
          <br></br>
          <br></br>
          </p>
          <div className='font-bold px-[5%]'>
            <span 
              className='badge' 
              style={{
                backgroundColor: 'rgba(76, 175, 80, 1)', 
                color: 'white', 
                padding: '0.25em 0.4em',
                marginRight: '0.2em',
                borderRadius: '0.8rem'}}
            >
            NEU!
            </span>
              Benutze einen unserer vielen verfügbaren Filter, um noch einfacher den perfekten Namen für dein Baby zu finden.
          </div>
          </div>
        </div>

      </section>

      <section className="mt-8">
      <div className='flex flex-col md:flex-row min-[770px]:space-x-4 space-y-4 min-[770px]:space-y-0 items-start'>
        <img src="/images/schnuller1.png" alt="Nutze einen unser vielen Features" className="my-4 min-[770px]:w-1/2 min-[650px]:order-2 " />
        
        <div className='min-[770px]:w-1/2 min-[770px]:pt-[5%]'>
        <h2 className="text-3xl font-bold mb-4 px-[5%]" style={{ fontSize: '20px', color: '#0069af' }}>
        Unsere Besonderheiten  
          <small className="text font-semibold block mt-2 text-gray-600" style={{ color: '#666666', fontSize: '16px' }}>
          
          </small>
        </h2>
        <p className='px-[5%]'>
        <ul className="list-disc list-inside pb-4">
            <li><strong>Umfangreiche <a href="/"className={
          "text-primary hover:text-secondary focus:text-secondary"}>Namenssammlung</a>:</strong> Durchsuche unser Sammlung von Babynamen und entdecke eine Vielzahl an Vornamen.</li>
            <li><strong>Filterfunktion:</strong>  Filtere zwischen Geschlecht, um personalisierte Vorschläge für Jungen- oder Mädchennamen zu erhalten.</li>
            <li><strong><a href="/myfavourites"className={
          "text-primary hover:text-secondary focus:text-secondary"}>Favoritenliste</a>:</strong> Speichere deine Lieblingsnamen und vergleiche sie, um den perfekten Namen zu finden.</li>
            <li><strong>Bewertungssystem:</strong> Bewerte Namen, damit sie nach deiner Vorliebe auf der Favoritenliste angezeigt werden.</li>
            <li><strong>Eigenen Namen hinzufügen:</strong> Dein Name war nicht in unserer Sammlung dabei? Füge einfach deinen eigenen Namen zur Favoritenliste hinzu.</li>
            <li><strong>PDF-Druck oder Speichern:</strong> Drucke oder speichere deine <a href="/myfavourites"className={
          "text-primary hover:text-secondary focus:text-secondary"}>Favoritenliste</a> als PDF, um sie mit deinen Liebsten zu teilen.</li>
          </ul>
        </p>
        </div>
        </div>
      </section>

      <section className="mt-8">
      <div className='flex flex-col md:flex-row min-[770px]:space-x-4 space-y-4 min-[770px]:space-y-0 items-start'>
        <img src="/images/flasche2.png" alt="Tipps zur Namenswahl" className="my-4 min-[770px]:w-1/2" />
  
        <div className='min-[770px]:w-1/2 min-[770px]:pt-[5%]'>
        <h2 className="text-3xl font-bold mb-4 px-[5%]" style={{ fontSize: '20px', color: '#0069af' }}>
        Tipps
          <small className="text font-semibold block mt-2 text-gray-600" style={{ color: '#666666', fontSize: '16px' }}>
        zur Namenswahl:
          </small>
        </h2>
        <p className='px-[5%]'>
        <ul className="list-disc list-inside pb-4">
            <li><strong>Klang und Harmonie:</strong> Achte darauf, dass der Vorname gut mit dem Nachnamen harmoniert.</li>
            <li><strong>Bedeutung:</strong> Wähle einen Namen mit einer positiven und tiefgründige Bedeutung.</li>
            <li><strong>Aussprache und Schreibweise:</strong> Vermeide komplizierte Schreibweisen, die später zu Verwirrungen führen könnten.</li>
            <li><strong>Zeitlosigkeit:</strong> Überlege, ob der Name auch in vielen Jahren noch passend sein wird.</li>
            <li><strong>Geschwisterkompatibilität:</strong> Falls du schon Kinder hast, achte darauf, dass die Namen gut zusammenpassen.</li>
          </ul>
        </p>
      </div>
      </div>
      </section>

      <section className="mt-8">
      <div className='flex flex-col md:flex-row min-[770px]:space-x-4 space-y-4 min-[770px]:space-y-0 items-start'>
        <img src="/images/krippe2.png" alt="Storch bringt Baby" className="my-4 min-[650px]:w-1/2 min-[650px]:order-2" />
        
        <div className='min-[770px]:w-1/2 min-[770px]:pt-[5%]'>
        <h2 className="text-3xl font-bold mb-4 px-[5%]" style={{ fontSize: '20px', color: '#0069af' }}>
        Schritt-für-Schritt-Anleitung:
          <small className="text font-semibold block mt-2 text-gray-600" style={{ color: '#666666', fontSize: '16px' }}>
          </small>
        </h2>
        <p className='px-[5%]'>
        <ul className="list-disc list-inside pb-4">
            <li><strong>Namenssuche:</strong> Durchsuche Internet, Bücher und Zeitschriften nach interessanten Namen.</li>
            <li><strong>Liste erstellen:</strong> Notiere alle Namen, die dir gefallen, und bespreche sie mit deinem Partner.</li>
            <li><strong>Auswahl eingrenzen:</strong> Streiche Namen, die zu häufig vorkommen oder schwer auszusprechen sind.</li>
            <li><strong>Vorlesetest:</strong> Lass die Namen von anderen laut vorlesen, um die Aussprache zu prüfen.</li>
            <li><strong>Partnerfeedback:</strong> Zeige deinem Partner die Liste und streiche Namen, die ihm/ihr nicht gefallen.</li>
            <li><strong>Kombinationstest:</strong> Teste die verbleibenden Namen in Kombination mit eurem Nachnamen.</li>
          </ul>
        </p>
        </div>
        </div>
      </section>


      <section className="mt-8">
      <div className='flex flex-col md:flex-row min-[770px]:space-x-4 space-y-4 min-[770px]:space-y-0 items-start'>
        <img src="/images/mobile.png" alt="Baby mobile" className="my-4 min-[770px]:w-1/2" />
        
        <div className='min-[770px]:w-1/2 min-[770px]:pt-[5%]'>
        <h2 className="text-3xl font-bold mb-4 px-[5%]" style={{ fontSize: '20px', color: '#0069af' }}>
        Rechtliche Aspekte:
        </h2>
        <p className='px-[5%]'>
        In Deutschland muss der Vorname das Geschlecht des Kindes erkennen lassen und darf nicht beleidigend 
            oder lächerlich sein. Namen wie "Judas" oder "Kain" sind unzulässig. Außerdem sollten die Namen 
            den Regeln der Rechtschreibung entsprechen und nicht zu viele sein – maximal fünf Vornamen sind erlaubt.
        <br></br>
        <br></br>
        </p>
        <h2 className="text-3xl font-bold mb-4 px-[5%]" style={{ fontSize: '20px', color: '#0069af' }}>
        Inspiration gefällig?
        </h2>
        <p className='px-[5%]'>
        Nutze unseren Babynamenfinder, um eine Vielzahl an Vorschlägen zu erhalten. Wähle das Geschlecht, 
        um die Auswahl einzugrenzen. So findest du schnell und einfach einen Namen, der perfekt zu deinem Baby passt.
        <br></br>
        <br></br>
        Lass dich von BabyNameFinder inspirieren und finde den Namen, der dein Herz höher schlagen lässt und deinem Baby ein Leben lang Freude bereiten wird!
        <br></br>
        <br></br>
        </p>
        </div>
        </div>
      </section>

      <section class="w-full w-md:w-1/2 h-100 flex flex-col justify-center ms-md-5 align-items-start">
        <h2 className=" text-3xl font-bolder mb-4 text-center" 
        style={{ fontSize: '20px', color: '#666666' }}>
          WORAUF WARTEST DU NOCH?
        </h2>

        <div className="flex w-75 mx-auto mx-md-0 flex-row gap-md-5 gap-2 mt-5 justify-content-center justify-content-md-start align-item-center align-items-md-start">
                {/* <a href="/" className="no-deco bg-primary text-neutral w-[12em] py-[1em] px-[1em] rounded-full cursor-pointer flex justify-center items-center font-bold hover:bg-primaryDark text-center">
                    Suche Starten
                </a> */}
                <a href="/" className="no-deco bg-secondary text-neutral w-[12em] py-[1em] px-[1em] rounded-full cursor-pointer flex justify-center items-center font-bold hover:bg-primaryDark text-center">
                    Mädchennamen
                </a>
                <a href="/" className="no-deco bg-primary text-neutral w-[12em] py-[1em] px-[1em] rounded-full cursor-pointer flex justify-center items-center font-bold hover:bg-primaryDark text-center">
                    Jungennamen
                </a>
        </div>
      </section>
      </div>

      );      
}

export default Info