import React from 'react'
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { setFilter, triggerUpdate } from '../app/features/filterSlice';
import { setMenuCategory, setMenuY, setMenuZIndex } from '../app/features/menuSlice'
import { useLazyFetchNamesQuery } from '../app/api/nameSlice'
import * as Accordion from '@radix-ui/react-accordion';
import * as RadioGroup from '@radix-ui/react-radio-group';
import * as Slider from '@radix-ui/react-slider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faTrash } from '@fortawesome/free-solid-svg-icons'


function FilterMenu() {

  const dispatch = useDispatch()
  const menu = useSelector((state) => state.menu)
  const filter = useSelector((state) => state.filter)
  const [accordionValue, setAccordionValue] = useState()

  
  const clickHandle = () => {
    if(menu.menuY !== '100%') {
        dispatch(triggerUpdate());
        dispatch(setMenuCategory('menu'))
        dispatch(setMenuY('100%'))
        setTimeout(() => {
            dispatch(setMenuZIndex(-1))
        }, 400);            
    } else {
        dispatch(setMenuZIndex(20))
        dispatch(setMenuY('10%'))
    }
  }

  const handleModalClick = (e) => {
    e.stopPropagation(); // Stoppt das Event-Bubbling vom Hintergrund-Abbrechen-Knopf
  };


  const cancelFilter = () => {
    dispatch(setFilter({...filter.prev, prev:{"gender": "", "name": {"startswith":"", "endswith":"", "length":[]}, "triggerUpdate":false}}))
    dispatch(setMenuCategory('menu'))
        dispatch(setMenuY('100%'))
        setTimeout(() => {
            dispatch(setMenuZIndex(-1))
        }, 400);         
  }


  return (
    <>  
        <div onClick={clickHandle} style={{zIndex: (menu.category==="filterMenu" ? menu.zIndex : -1) }} className={(menu.menuY === "100%" ? "menu-bg-light" : 'menu-bg-dark') + " relative row-start-2 col-start-1 overflow-hidden md:static md:row-start-1 md:row-end-1 md:col-start-1 md:col-end-3 md:bg-[transparent]"}>
            <div onClick={handleModalClick} style={{transition: 'top 0.4s ease', top: menu.menuY}} className='h-[90%] absolute rounded-t-[35px] bg-neutral w-full overflow-auto md:rounded-[35px] md:static md:w-[400px] md:h-fit md:ml-auto md:mt-[120px] md:mr-16 md:shadow-md'>
                <Accordion.Root
                  className="rounded-t-[35px]"
                  type="single"
                  // defaultValue="item-1"
                  value={accordionValue}
                  onValueChange={(value)=>setAccordionValue(value)}
                  collapsible
                >
                    <AccordionItem value="item-1" >
                      <AccordionTrigger className="rounded-t-[35px]">
                        <div className='flex text-start'>
                          <p className='w-[110px] '>Geschlecht</p>
                          {filter.gender !== "" ?
                            <p className="text-primary font-bold text-nowrap text-ellipsis overflow-hidden w-44">
                              {{"f":"weiblich", "m":"männlich", "":"Alle", "fm":"Alle"}[filter.gender]}
                            </p> : <></>
                          }

                        </div>
                        <div className='flex items-center'>
                          {filter.gender!=="" && 
                            <div className='grid p-2 mr-4 relative hover:text-primary'>
                              <FontAwesomeIcon icon={faTrash} className="text-inherit col-span-1 row-span-1 text-[2rem] cursor-pointer"/>
                              <a className="opacity-0 col-span-1 row-span-1 absolute top-0 bottom-0 w-full text-neutralDarker" onClick={(e)=>{dispatch(setFilter({...filter, gender: ""}));e.stopPropagation(); setAccordionValue([])}}></a>
                            </div>
                          }
                          <FontAwesomeIcon icon={faChevronRight} className="text-[2rem] w-[3rem] cursor-pointer ease-[cubic-bezier(0.87,_0,_0.13,_1)] transition-transform duration-300 group-data-[state=open]:rotate-90"/>
                        </div>
                      </AccordionTrigger>
                      <AccordionContent>
                          <RadioGroup.Root
                            className="flex flex-col gap-6 required:text-primary py-4"
                            value={filter.gender===""? "fm": filter.gender}
                            aria-label="View density"
                            onValueChange={(value)=>dispatch(setFilter({...filter, gender: value}))}
                            required
                          >
                              <div className="flex items-center">
                                  <RadioGroup.Item
                                    className="bg-neutral w-[20px] h-[20px] rounded-full focus:shadow-[0_0_0_2px] focus:shadow-[--primary] hover:shadow-[0_0_0_2px] hover:shadow-[--primary] shadow-[0_0_0_1px] shadow-[--primary] cursor-pointer"
                                    value="fm"
                                    id="r1"
                                  >
                                  <RadioGroup.Indicator className="flex items-center justify-center w-full h-full relative after:bg-[--primary] after:content-[''] after:block after:w-[12px] after:h-[12px] after:rounded-[50%]" />
                                  </RadioGroup.Item>
                                  <label className="leading-none pl-[15px]" htmlFor="r1">
                                  Alle
                                  </label>
                              </div>
                              <div className="flex items-center">
                                  <RadioGroup.Item
                                    className="bg-neutral w-[20px] h-[20px] rounded-full focus:shadow-[0_0_0_2px] focus:shadow-[--primary] hover:shadow-[0_0_0_2px] hover:shadow-[--primary] shadow-[0_0_0_1px] shadow-[--primary] cursor-pointer"
                                    value="f"
                                    id="r2"
                                  >
                                  <RadioGroup.Indicator className="flex items-center justify-center w-full h-full relative after:bg-[--primary] after:content-[''] after:block after:w-[12px] after:h-[12px] after:rounded-[50%]" />
                                  </RadioGroup.Item>
                                  <label className="leading-none pl-[15px]" htmlFor="r1">
                                  weiblich
                                  </label>
                              </div>
                              <div className="flex items-center">
                                  <RadioGroup.Item
                                    className="bg-neutral w-[20px] h-[20px] rounded-full focus:shadow-[0_0_0_2px] focus:shadow-[--primary] hover:shadow-[0_0_0_2px] hover:shadow-[--primary] shadow-[0_0_0_1px] shadow-[--primary] cursor-pointer"
                                    value="m"
                                    id="r3"
                                  >
                                  <RadioGroup.Indicator className="flex items-center justify-center w-full h-full relative after:bg-[--primary] after:content-[''] after:block after:w-[12px] after:h-[12px] after:rounded-[50%]" />
                                  </RadioGroup.Item>
                                  <label className="leading-none pl-[15px]" htmlFor="r2">
                                  männlich
                                  </label>
                              </div>
                          </RadioGroup.Root>
                      </AccordionContent>
                    </AccordionItem>

                    <AccordionItem value="item-2">
                      <AccordionTrigger className="rounded-t-[35px]">
                        <div className='flex text-start'>
                          <p className='w-[110px]'>Startet mit...</p>
                          {filter.name.startswith.length>0 ?
                            <p className="text-primary font-bold text-nowrap text-ellipsis overflow-hidden w-44">
                              {filter.name.startswith}
                            </p> : <></>
                          }

                        </div>
                        <div className='flex items-center'>
                          {filter.name.startswith.length>0 && 
                            <div className='grid p-2 mr-4 relative hover:text-primary'>
                              <FontAwesomeIcon icon={faTrash} className="text-inherit col-span-1 row-span-1 text-[2rem] cursor-pointer"/>
                              <a className="opacity-0 col-span-1 row-span-1 absolute top-0 bottom-0 w-full text-neutralDarker" onClick={(e)=>{dispatch(setFilter({...filter, name: {...filter.name, startswith: ""}}));e.stopPropagation(); setAccordionValue([])}}></a>
                            </div>
                          }
                          <FontAwesomeIcon icon={faChevronRight} className="text-[2rem] w-[3rem] cursor-pointer ease-[cubic-bezier(0.87,_0,_0.13,_1)] transition-transform duration-300 group-data-[state=open]:rotate-90"/>
                        </div>
                      </AccordionTrigger>
                      <AccordionContent>
                        <input 
                            value={filter.name.startswith} 
                            onChange={(e)=>dispatch(setFilter({...filter, name: {...filter.name, startswith: e.target.value}}))}
                            className="my-4" 
                            placeholder='Startet mit ...'>
                        </input>
                      </AccordionContent>
                    </AccordionItem>

                    <AccordionItem value="item-3">
                      <AccordionTrigger className="rounded-t-[35px]">
                        <div className='flex text-start'>
                          <p className='w-[110px] '>Endet mit...</p>
                          {filter.name.endswith.length>0 ?
                            <p className="text-primary font-bold text-nowrap text-ellipsis overflow-hidden w-44">
                              {filter.name.endswith}
                            </p> : <></>
                          }

                        </div>
                        <div className='flex items-center'>
                          {filter.name.endswith.length>0 && 
                            <div className='grid p-2 mr-4 relative hover:text-primary'>
                              <FontAwesomeIcon icon={faTrash} className="text-inherit col-span-1 row-span-1 text-[2rem] cursor-pointer"/>
                              <a className="opacity-0 col-span-1 row-span-1 absolute top-0 bottom-0 w-full text-neutralDarker" onClick={(e)=>{dispatch(setFilter({...filter, name: {...filter.name, endswith: ""}}));e.stopPropagation(); setAccordionValue([])}}></a>
                            </div>
                          }
                          <FontAwesomeIcon icon={faChevronRight} className="text-[2rem] w-[3rem] cursor-pointer ease-[cubic-bezier(0.87,_0,_0.13,_1)] transition-transform duration-300 group-data-[state=open]:rotate-90"/>
                        </div>
                      </AccordionTrigger>
                      <AccordionContent>
                        <input 
                          value={filter.name.endswith} 
                          onChange={(e)=>dispatch(setFilter({...filter, name: {...filter.name, endswith: e.target.value}}))}
                          className="my-4" 
                          placeholder='Endet mit ...'>
                        </input>
                      </AccordionContent>
                    </AccordionItem>

                    <AccordionItem value="item-4">
                    <AccordionTrigger className="rounded-t-[35px]">
                        <div className='flex text-start'>
                          <p className='w-[110px] '>Länge</p>
                          {filter.name.length.length===2 ?
                            <p className="text-primary font-bold text-nowrap text-ellipsis overflow-hidden w-44">
                              {`${filter.name.length[0]} - ${filter.name.length[1]}`}
                            </p> : <></>
                          }

                        </div>
                        <div className='flex items-center'>
                          {filter.name.length.length===2 && 
                            <div className='grid p-2 mr-4 relative hover:text-primary'>
                              <FontAwesomeIcon icon={faTrash} className="text-inherit col-span-1 row-span-1 text-[2rem] cursor-pointer"/>
                              <a className="opacity-0 col-span-1 row-span-1 absolute top-0 bottom-0 w-full text-neutralDarker" onClick={(e)=>{dispatch(setFilter({...filter, name: {...filter.name, length: []}}));e.stopPropagation(); setAccordionValue([])}}></a>
                            </div>
                          }
                          <FontAwesomeIcon icon={faChevronRight} className="text-[2rem] w-[3rem] cursor-pointer ease-[cubic-bezier(0.87,_0,_0.13,_1)] transition-transform duration-300 group-data-[state=open]:rotate-90"/>
                        </div>
                      </AccordionTrigger>
                      <AccordionContent>
                        <Slider.Root
                          className="relative flex items-center select-none touch-none w-[200px] h-5 py-10 mx-auto"
                          value={filter.name.length.length===2 ? filter.name.length : [0,30]}
                          onValueChange={(values)=>dispatch(setFilter({...filter, name: {...filter.name, length: values}}))}
                          max={30}
                          step={1}
                        >
                          <Slider.Track className="bg-neutralLight relative grow rounded-full h-[3px]">
                            <Slider.Range className="absolute bg-primary rounded-full h-full" />
                          </Slider.Track>
                          <Slider.Thumb
                            className="block w-6 h-6 bg-primary rounded-[10px] focus:outline-none focus:shadow-[0_0_0_2px] focus:shadow-primary focus:border-[3px] focus:border-neutral"
                            aria-label="Volume"
                          />
                          <Slider.Thumb
                            className="block w-6 h-6 bg-primary rounded-[10px] focus:outline-none focus:shadow-[0_0_0_2px] focus:shadow-primary focus:border-[3px] focus:border-neutral"
                            aria-label="Volume"
                          />
                        </Slider.Root>
                      </AccordionContent>
                    </AccordionItem>
                </Accordion.Root>

                <div style={{zIndex: (menu.category==="filterMenu" ? 30 : -1)}} className='fixed bottom-0 left-0 w-full h-[7em] bg-neutral flex justify-evenly items-center md:static'>
                  <button onClick={cancelFilter} className='py-4 w-[150px] rounded-full bg-neutral border-solid border border-primary text-primaryDark hover:bg-primary focus:bg-primary hover:text-neutral focus:text-neutral'>Abbrechen</button>
                  <button onClick={clickHandle} className='py-4 w-[150px] rounded-full border-solid border border-primary hover:bg-primary focus:bg-primary'>Filtern</button>
                </div>
            </div>
        </div>

        <div style={{zIndex: (menu.category==="filterMenu" ? 30 : -1)}} className='fixed bottom-0 w-full h-[7em] bg-neutral flex justify-evenly items-center md:hidden'>
            <button onClick={cancelFilter} className='py-4 w-[150px] rounded-full bg-neutral border-solid border border-primary text-primaryDark hover:bg-primary focus:bg-primary hover:text-neutral focus:text-neutral'>Abbrechen</button>
            <button onClick={clickHandle} className='py-4 w-[150px] rounded-full border-solid border border-primary hover:bg-primary focus:bg-primary'>Filtern</button>
        </div>
    </>
  )
}



const AccordionItem = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
    <Accordion.Item
      className={
        'mt-px overflow-hidden'
        }
      {...props}
      ref={forwardedRef}
    >
      {children}
    </Accordion.Item>
  ));
  
  const AccordionTrigger = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
    <Accordion.Header className="flex h-32 border-b-[0.5px] border-neutralLighter md:h-24">
      <Accordion.Trigger
        className={
          'text-neutralDark text-xl px-12 group flex flex-1 cursor-pointer items-center justify-between bg-white shadow-sm'
        }
        {...props}
        ref={forwardedRef}
      >
        {children}
      </Accordion.Trigger>
    </Accordion.Header>
  ));
  
  const AccordionContent = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
    <Accordion.Content
      className={
        'bg-[#F6F6F6] px-20 data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp overflow-hidden text-xl '
        }
      {...props}
      ref={forwardedRef}
    >
      <div className="py-[15px] px-5">{children}</div>
    </Accordion.Content>
  ));

export default FilterMenu