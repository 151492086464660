import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { getAccessToken } from "../../utils/localStorage";


const baseQuery = fetchBaseQuery({ 
  baseUrl: "",
  prepareHeaders: (headers) => {
    const token = getAccessToken();

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const apiSlice = createApi({
  baseQuery,
  tagTypes: ["Favourite", "Name"],
  endpoints: () => ({}),
});