import { apiSlice } from "./apiSlice";


const USER_URL = "/api/users"

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createUser: builder.mutation({
      query: (newUser) => ({
        url: `${USER_URL}`,
        method: "POST",
        body: newUser,
      }),
      invalidatesTags: ['User']
    }),
  })
});

export const {
  useCreateUserMutation,
} = userApiSlice;