import { createSlice } from "@reduxjs/toolkit";

const filterSlice = createSlice({
  name: "filter",
  initialState: {
    "gender": "",
    "name": {
      "startswith":"",
      "endswith":"",
      "length":[]
    },
    "prev": {
      "gender": "",
      "name": {
        "startswith":"",
        "endswith":"",
        "length":[]
      },
      "triggerUpdate":false,
    },
    "triggerUpdate":false
  },
  reducers: {
    setFilter: (state, action) => {
      return action.payload;
    },
    triggerUpdate(state) {
      console.log('triggered')
      state.triggerUpdate = !state.triggerUpdate;
    },
  },
});

export const { setFilter, triggerUpdate } =
  filterSlice.actions;
export default filterSlice.reducer;